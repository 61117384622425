@font-face {
  font-family: "Gotham-Medium";
  src:
    url("./gotham/GothamMedium.ttf") format("woff"),
    url("./gotham/GothamMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham-Black";
  src:
    url("./gotham/Gotham-Black.otf") format("woff"),
    url("./gotham/Gotham-Black.otf") format("truetype");
}

@font-face {
  font-family: "Gotham-Ligth";
  src:
    url("./gotham/GothamLight.ttf") format("woff"),
    url("./gotham/GothamLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham-Book";
  src:
    url("./gotham/GothamBook.ttf") format("woff"),
    url("./gotham/GothamBook.ttf") format("truetype");
}
