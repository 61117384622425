@import "styles/variables";
@import "components/index";
@import "features/index";
@import "styles/global";
@import "react-toastify/dist/ReactToastify.css";

body {
  background-color: $fill;
  font-size: 20px;
  line-height: 1em;
  margin: 0;
  font-family: $baseFont;
  user-select: none;

  @media screen and (max-width: 1660px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
}

.root--file-cancel {
  padding-top: 1em;
}

.Toastify {
  &__toast {
    padding: 20px 25px;
    font-family: $gothamBook;

    p {
      line-height: 20px;
    }
  }
}
