@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.products-page {
  width: 30em;
  margin: 2em auto;

  &--tooltip-paraq {
    margin: 0;
    padding-bottom: 1em;
    font-family: $gothamBook;
    color: $base;
    width: 24em;
    &:last-child {
      padding-bottom: 0;
    }
  }

  &--tooltip-paraq-half {
    width: 12em;
    margin: 0;
    font-family: $gothamBook;
    color: $base;
    padding-bottom: 0.25em;
  }

  &--relative-label {
    position: relative;
    display: flex !important;
    align-items: center !important;
  }

  &--relative-icon {
    color: $base;
    position: relative;
    margin-left: 0.5em;
  }

  &--payment-side {
    width: 22em;
    text-align: left;
  }

  &--vat-number {
    padding: 0 0 1em;
  }

  &--total {
    min-width: 5.5rem;
    text-align: right;
  }

  &--full-text {
    width: 100%;
    padding: 0 0 0.25em;
  }

  &--payment-dialog {
    padding: 1em;
    gap: 2.5em;
  }

  &--billing-address-saved {
    animation: appear ease-out 0.3s 1;
  }

  &--cancel-code {
    padding: 0.25em;
    margin: 0;
    background: transparent;
    border: none;
    position: absolute;
    right: 0.5em;
    top: 0.25em;
    cursor: pointer;
    vertical-align: top;
  }

  &--button-wrapper {
    padding-top: $mpd;
    padding-bottom: $mpd;
  }

  &--button-wrapper-last {
    padding-bottom: $xxlpd;
  }

  &--selected-product {
    padding: 1em 0;
    width: 100%;
  }

  &--text {
    text-align: left;
  }

  &--input-wrapper {
    padding-bottom: $xspd;
  }

  &--text-wrapper {
    padding-bottom: $mpd;
  }

  &--product-wrapper {
    flex-wrap: wrap;
    padding: $xxlpd 0 0;
  }

  &--radio-wrapper {
    padding: 1.5em 0;
  }

  &--proceed-payment {
    width: 100%;
    height: 2.5em;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  &--save-address {
    width: 65%;
    height: 2.5em;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  &--proceed-payment-cancel {
    background: transparent;
  }

  &--radio {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50%;

    &--label {
      position: relative;
      padding: 0.75em 0;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &--icon {
      margin-right: 0.5em;
    }

    &--text {
      position: relative;
      vertical-align: middle;
      height: 22px;
      * {
        vertical-align: middle;
      }
    }
  }

  &--input {
    width: 30%;
    padding-bottom: 0.25em;
    position: relative;

    label {
      cursor: pointer;
    }

    &--medium {
      width: 40%;
    }

    &--half {
      width: 50%;
    }

    &--wide-m {
      width: 55%;
    }

    &--wide {
      width: 65%;
      padding-bottom: 0.25em;
    }

    &--wide-x {
      width: 85%;
      padding-bottom: 0.25em;
    }

    &--full {
      width: 100%;
    }

    label {
      color: $base;
      font-size: $xs;
      display: block;
      text-align: left;
      font-family: $gothamLight;
      padding-bottom: 0.5em;
    }
    input,
    textarea {
      background-color: $inputBgGrey;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      line-height: 1em;
      height: 1.65em;
      font-size: $xs;
      color: $black;
      border: none;
      padding: 0.25em 0.5em;
      outline: none;
      font-family: $gothamLight;
      &.error {
        border-color: $error;
        color: $error;
      }
      &.success {
        border-color: $green;
        color: $green;
      }
    }
    textarea {
      height: 5.75em;
      resize: none;
    }
  }

  &--item {
    transition: all ease-out 0.3s;
    border-left: solid 4px $pink;
    margin-bottom: $mpd;
    padding: $xspd 0;
    cursor: pointer;
    min-height: 3.25em;
    position: relative;
    overflow: hidden;

    &--sub-bold {
      font-family: $gothamBlack, "Verdana";
      font-weight: 700;
    }

    &--details {
      transition: all ease-out 0.3s;
      position: absolute;
      opacity: 1;
      left: 4px;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 !important;
      transform: translateX(-100%);
    }

    &:hover {
      transform: scale(1.05);
      div.products-page--item--details {
        transform: translateX(0);
      }
    }

    &--half {
      width: 47.5%;
    }

    &--full {
      width: 100%;
    }

    &--grey {
      background-color: $darkGrey;
    }

    &--white {
      background-color: $base;
    }

    &--pink {
      padding: $mpd 0;
      background-color: $pink;
      border-left-color: $base;
    }
  }

  &--product-helper {
    width: auto;
    min-width: auto;
    padding: 0.45em 1.125em;
    height: auto;
    border-radius: 0;
  }

  &--modal-info {
    text-align: left;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }
  }

  &--list {
    padding-right: 3em;
    color: $base;
    & li {
      font-size: 1em;
      font-weight: normal;
      font-family: $gothamBook;
    }
  }

  &--button {
    transition: all 0.3s ease-out;
    cursor: pointer;
    background-color: $pink;
    border-radius: 6px;
    text-transform: uppercase;
    font-family: $gothamBlack;
    color: $base;
    border: none;
    padding: 0.5em 0.35em;
    font-size: $xl;

    &--activate {
      background: $inputBgGrey;
      border-radius: 6px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.28em;
      width: 5.5em;
      margin-bottom: 0.24em;
      text-transform: none;
      &--disabled {
        opacity: 0.5 !important;
      }
    }

    &--later {
      color: $black;
      background-color: $grey;
      line-height: $spd;
      padding: ($xspd / 2) 0.5em 0.35em;
    }
    &:disabled {
      cursor: default;
      background-color: $grey;
    }
    &:hover {
      opacity: 0.8;
    }

    &--paypal {
      width: 100%;
      background-color: $orange;
    }

    &--pay {
      color: #102f82;
      font-size: 24px;
      font-family: $gothamBlack;
      text-transform: initial;
    }
    &--pal {
      color: #459ad8;
      font-size: 24px;
      font-family: $gothamBlack;
      text-transform: initial;
    }

    &--credit {
      width: 100%;
      font-family: $gothamLight;
      text-transform: initial;
      & > * {
        vertical-align: middle;
      }
      & > svg {
        margin-bottom: 4px;
      }
      &-span {
        display: inline-block;
        font-size: 0.875em;
        line-height: 1em;
        font-family: $gothamBook;
        padding: 0 0 0 $xspd;
        color: $base;
      }
    }
  }

  &--button-between {
    padding-top: $mpd;
    width: 100%;
    display: flex;
    justify-content: center;
    & > * {
      margin: 0 0.5em;
    }
  }

  &--relate-input {
    position: relative;
  }

  &--groups-text {
    display: flex;
    align-items: center;

    .tooltip-wrapper--modal {
      text-transform: none;
      top: -160px;

      img {
        width: 520px;
      }
    }

    .products-page--tooltip-paraq {
      width: 100%;
    }
  }
}
