.add-product-page {
  width: 55em;
  margin: 2em auto;
  user-select: text;

  &--login {
    padding-top: 10em;
  }

  &--section {
    padding-bottom: 1em;
  }

  &--action {
    margin-right: 1em;
  }

  &--select {
    position: relative;
    input {
      cursor: pointer;
    }
  }

  &--calendar {
    position: relative;
    &--icon {
      position: absolute;
      right: 0.5em;
      top: 0.3em;
    }
    * {
      cursor: pointer;
    }
  }

  &--prefix {
    position: absolute;
    right: 0.5em;
    top: 1.5em;
  }

  &--modal {
    position: absolute;
    background-color: $inputBgGrey;
    top: calc(100% - 0.75em);
    left: 0;
    border-radius: 5px;
    padding: 0.25em 0;
    &--item {
      padding: 0.4em 0;
      color: $black;
      position: relative;
      cursor: pointer;

      &--root {
        padding: 0 0.5em;
      }

      &--disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  &--checkbox {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: 0;

    &--label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--radio {
      min-width: 1em;
      min-height: 1em;
      margin-right: 1em;
    }

    &--icon {
      min-width: 1em;
      min-height: 1em;
      margin-top: 0.125em;
      margin-right: 1em;
    }

    &--text {
      color: $base;
      text-align: left;
      &--bold {
        font-family: $gothamBlack;
      }
    }

    &--modal-text {
      color: $black;
      text-align: left;
      &--bold {
        font-family: $gothamBlack;
      }
    }

    &--modal-icon {
      min-width: 1em;
      min-height: 1em;
      margin-right: 0.5em;
    }
  }

  &--data {
    border: solid 1px $inputBgGrey;
    border-radius: 12px;
    padding: 0 1em;
    background: $darkGrey;
  }

  &--item {
    width: 30%;
    padding-bottom: 1em;

    label {
      cursor: pointer;
    }

    &--span {
      color: $base;
    }

    &--half {
      width: 50%;
    }

    &--wide {
      width: 65%;
      padding-bottom: 1em;
    }

    &--full {
      width: 100%;
    }

    label {
      color: $base;
      font-size: $mpd;
      display: block;
      font-family: $gothamLight;
      padding-bottom: 0.25em;
    }
    input,
    textarea {
      background-color: $inputBgGrey;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      line-height: 1em;
      height: 1.75em;
      font-size: $mpd;
      color: $black;
      border: none;
      padding: 0.25em 0.5em;
      outline: none;
      font-family: $gothamLight;
    }
    textarea {
      height: 5.75em;
      resize: none;
    }
  }

  &--button {
    & > * {
      margin: 0 0.5em;
    }
  }

  &--table {
    padding: 1em 0;
    table {
      width: 100%;
      td,
      th {
        border-bottom: solid 1px $grey;
        padding: 0.5em;
        text-align: left;
        color: $base;
      }
      th {
        font-size: 0.875em;
      }
      td {
        font-size: 0.625em;
      }
    }
  }

  &--file-name {
    padding-bottom: 1em;
  }

  &--file-cancel {
    padding-top: 1em;
  }

  &--user {
    & > * {
      margin: 0 1em;
    }
  }

  &--promo-code {
    & > * {
      margin: 0 1em;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--tab-links {
    transition: opacity 0.3s ease-out;
    padding: 0 0 0.5em 0;
    border: solid 1px transparent;
    font-size: 1em;
    cursor: pointer;
    background: none;
    display: inline-block;
    color: $base;
    margin-right: 1em;
    margin-bottom: 1em;
    &--active {
      border-bottom: solid 1px $pink;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  &--file {
    position: relative;
    height: 2.5em;
    & > input {
      opacity: 0;
    }
    & > span {
      transition: opacity 0.3s ease-out;
      cursor: pointer;
      color: $base;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      display: block;
      text-align: center;
      border: solid 1px $grey;
      border-radius: 12px;
      padding: 0.5em;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &--settings-wrapper {
    padding-bottom: 0.5em;
  }

  &--settings-hook {
    &--header {
      padding: 1em 0;
    }
    &--part {
      padding-right: 1em;
    }
  }

  &--settings-text {
    padding-right: 1em;
  }

  &--settings-hook {
    padding-top: 2em;
  }

  &--setting-params {
    padding-top: 0.5em;
  }
}
