.button {
  transition: all 0.3s ease-out;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  min-width: 6.52em;
  text-transform: uppercase;
  font-family: $gothamBook;
  border: none;
  padding: 0.5em 0.35em;
  font-size: $xs;
  height: $hxl;

  &--suffix {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    bottom: 0.5em;
    & > * {
      width: 1.5em;
      height: 1.5em;
    }
  }

  &--small {
    line-height: $spd;
    padding: 0.25em 0.5em;
    height: 1.625em;
  }

  &--color-pink {
    background-color: $pink;
    color: $base;
  }

  &--color-grey {
    background-color: $grey;
    color: $black;
  }

  &--color-white {
    background-color: transparent;
    color: $base;
  }

  &:disabled {
    cursor: default;
    background-color: $grey;
  }

  &:hover {
    opacity: 0.8;
  }
}
