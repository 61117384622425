.tooltip-wrapper {
  position: relative;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 40px;

  &:hover {
    .tooltip-wrapper--modal {
      display: block;
    }
  }

  &--modal {
    display: none;
    position: absolute;
    top: 100%;
    left: 100%;
    padding: 0.5em;
    border-radius: 6px;
    background: $darkGrey;
    z-index: 4;
  }
}
