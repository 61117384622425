@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  display: inline-block;

  &:after,
  &:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    border: solid 3px;
  }

  &:after {
    border-right-color: $pink;
    animation: rotation linear 1s infinite;
  }
}
