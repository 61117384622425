.background-blocker {
  transition: all 0.3s ease-out;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);

  &--overflow {
    overflow: auto;
    align-items: flex-start;
    padding: 2em 0;
  }
}
